const requestApi = require("@/lib/request");
// 用户接口
module.exports = {
    //用户列表
    vipList(data) {
        return new Promise((resolve, reject) => {
            requestApi.userRequest('/api/vip/vip-list', data).then(res => {
                resolve(res);
            }).catch(res => {
                reject(res);
            });
        });
    },
    //用户操作
    vipForm(data) {
        return new Promise((resolve, reject) => {
            requestApi.userRequest('/api/vip/vip-form', data).then(res => {
                resolve(res);
            }).catch(res => {
                reject(res);
            });
        });
    },
    //用户标签操作
    vipLabelForm(data) {
        return new Promise((resolve, reject) => {
            requestApi.userRequest('/api/vip/vip-label-form', data).then(res => {
                resolve(res);
            }).catch(res => {
                reject(res);
            });
        });
    },
    //用户分组列表
    vipGroupList(data) {
        return new Promise((resolve, reject) => {
            requestApi.userRequest('/api/vip/vip-group-list', data).then(res => {
                resolve(res);
            }).catch(res => {
                reject(res);
            });
        });
    },
    //用户分组操作
    vipGroupForm(data) {
        return new Promise((resolve, reject) => {
            requestApi.userRequest('/api/vip/vip-group-form', data).then(res => {
                resolve(res);
            }).catch(res => {
                reject(res);
            });
        });
    },
    //用户分组删除
    vipGroupDelete(data) {
        return new Promise((resolve, reject) => {
            requestApi.userRequest('/api/vip/vip-group-delete', data).then(res => {
                resolve(res);
            }).catch(res => {
                reject(res);
            });
        });
    },
}