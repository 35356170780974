<template>
  <div class="main_container">
    <div class="main_page">
      <div class="page_title">{{ $route.meta.title }}</div>
      <div class="page_containt">
        <div class="page_search">
          <el-form ref="searchForm" :model="searchForm">
            <el-row type="flex">
              <el-col class="search_col">
                <el-form-item size="small" prop="key">
                  <el-input
                    size="small"
                    type="text"
                    v-model="searchForm.key"
                    style="width: 180px"
                    placeholder="关键字"
                  />
                </el-form-item>
              </el-col>
              <el-col class="search_col">
                <el-button
                  type="primary"
                  size="small"
                  native-type="submit"
                  @click.native.prevent="handleSearch"
                  >搜索</el-button
                >
              </el-col>
              <el-col class="search_col">
                <el-button size="small" @click="resetSearchForm"
                  >重置</el-button
                >
              </el-col>
              <el-col class="btn_col">
                <el-button type="primary" size="small" @click="doForm(null)"
                  >新增分组</el-button
                >
              </el-col>
            </el-row>
          </el-form>
        </div>
        <div class="page_box" ref="pageBox">
          <el-table
            v-loading="loading"
            size="small"
            class="el_table"
            cell-class-name="el_table_cell"
            header-row-class-name="el_table_header"
            :data="listData"
            :height="tableHeight"
            style="width: 100%"
          >
            <el-table-column prop="name" label="名称"> </el-table-column>
            <el-table-column prop="created_at" label="创建日期" width="140">
            </el-table-column>
            <el-table-column
              fixed="right"
              align="right"
              header-align="right"
              label="操作"
              width="275"
            >
              <template slot-scope="scope">
                <el-button
                  class="btn"
                  type="text"
                  icon="el-icon-user"
                  size="small"
                  @click="userList(scope.row)"
                >
                  用户列表
                </el-button>
                <el-button
                  class="btn"
                  type="text"
                  icon="el-icon-edit"
                  size="small"
                  @click="doForm(scope.row)"
                >
                  编辑
                </el-button>
                <el-button
                  @click="deleteItem(scope.row)"
                  class="btn"
                  type="text"
                  icon="el-icon-delete"
                  size="small"
                >
                  删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <el-drawer
      title="用户列表"
      size="80%"
      :visible.sync="drawer"
      direction="rtl"
      :wrapperClosable="false"
      :close-on-press-escape="false"
    >
      <div class="drawer_box">
        <div class="page_search">
          <el-form ref="vipSearchForm" :model="vipSearchForm">
            <el-row type="flex">
              <el-col class="search_col">
                <el-form-item size="small" prop="key">
                  <el-input
                    size="small"
                    type="text"
                    v-model="vipSearchForm.key"
                    style="width: 180px"
                    placeholder="ID/姓名"
                  />
                </el-form-item>
              </el-col>
              <el-col class="search_col">
                <el-form-item size="small" prop="begin_at">
                  <el-date-picker
                    size="small"
                    style="width: 150px"
                    v-model="vipSearchForm.begin_at"
                    type="date"
                    value-format="yyyy-MM-dd"
                    placeholder="创建开始日期"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col class="search_col" style="line-height: 32px"> 至 </el-col>
              <el-col class="search_col">
                <el-form-item size="small" prop="end_at">
                  <el-date-picker
                    size="small"
                    style="width: 150px"
                    v-model="vipSearchForm.end_at"
                    type="date"
                    value-format="yyyy-MM-dd"
                    placeholder="创建结束日期"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col class="search_col">
                <el-button
                  type="primary"
                  size="small"
                  native-type="submit"
                  @click.native.prevent="handleVipSearch"
                  >搜索</el-button
                >
              </el-col>
              <el-col class="search_col">
                <el-button size="small" @click="resetVipSearchForm"
                  >重置</el-button
                >
              </el-col>
            </el-row>
          </el-form>
        </div>
        <div class="page_box" ref="pageBox">
          <el-table
            v-loading="loading"
            size="small"
            class="el_table"
            cell-class-name="el_table_cell"
            header-row-class-name="el_table_header"
            :data="vipListData"
            :height="tableHeight"
            style="width: 100%"
          >
            <el-table-column prop="vip_no" label="ID" width="120">
            </el-table-column>
            <el-table-column label="头像" width="60">
              <template slot-scope="scope">
                <el-avatar
                  :src="scope.row.face_url"
                  v-if="scope.row.face_url"
                  :size="50"
                ></el-avatar>
                <el-avatar
                  icon="el-icon-user-solid"
                  v-else
                  :size="50"
                ></el-avatar>
              </template>
            </el-table-column>
            <el-table-column prop="name" label="姓名" width="150">
            </el-table-column>
            <el-table-column label="标签" min-width="300">
              <template slot-scope="scope">
                <el-tag
                  type="info"
                  v-for="(item, index) in scope.row.label"
                  :key="index"
                  >{{ item }}</el-tag
                >
              </template>
            </el-table-column>
            <el-table-column prop="group_name" label="分组" width="100">
            </el-table-column>
            <el-table-column label="邀请人ID" width="120">
              <template slot-scope="scope">
                {{ scope.row.topVip ? scope.row.topVip.vip_no : "" }}
              </template>
            </el-table-column>
            <el-table-column label="邀请人" width="150">
              <template slot-scope="scope">
                {{ scope.row.topVip ? scope.row.topVip.name : "" }}
              </template>
            </el-table-column>
            <el-table-column prop="look_num" label="观看次数" width="100">
            </el-table-column>
            <el-table-column prop="look_time_name" label="观看时长" width="150">
            </el-table-column>
            <el-table-column prop="logined_count" label="登录次数" width="140">
            </el-table-column>
            <el-table-column prop="logined_at" label="最近登录日期" width="140">
            </el-table-column>
            <el-table-column prop="ip" label="IP" width="100">
            </el-table-column>
            <el-table-column prop="address" label="地区" min-width="150">
            </el-table-column>
            <el-table-column prop="created_at" label="创建日期" width="140">
            </el-table-column>
          </el-table>
          <div class="page_pagination">
            <el-pagination
              background
              layout="total,prev,pager,next,jumper"
              @current-change="handleVipCurrentChange"
              :total="vipPageConfig.counts"
              :page-size="vipPageConfig.pageSize"
              :current-page="vipPageConfig.pageIndex"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </el-drawer>
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      @close="dialogVisible = false"
      width="400px"
    >
      <el-form
        ref="modelForm"
        :model="modelForm"
        :rules="modelRules"
        label-width="100px"
        size="small"
        class="form_box"
      >
        <el-form-item label="名称：" class="el-form-item-no" prop="name">
          <el-input
            style="width: 200px"
            size="small"
            v-model.trim="modelForm.name"
            placeholder=""
          ></el-input>
        </el-form-item>
        <el-form-item label="">
          <el-button
            type="primary"
            native-type="submit"
            style="width: 80px"
            @click.native.prevent="handleSubmit"
            size="small"
            :loading="btnLogining"
            >确定</el-button
          >
          <el-button style="width: 80px" @click="dialogVisible = false"
            >取消</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
  <script>
import { onTableHeight } from "@/lib/table";
import vipApi from "@/api/vip";
export default {
  data() {
    return {
      loading: false,
      btnLogining: false,
      dialogVisible: false,
      drawer: false,
      model: null,
      searchForm: {
        key: "",
      },
      listData: [],
      tableHeight: 500,
      dialogTitle: "",
      modelForm: {
        name: "",
      },
      modelRules: {
        name: [
          { required: true, message: "请输入名称", trigger: "blur" },
          { min: 1, max: 100, message: "长度不超过100个字符" },
        ],
      },
      vipSearchForm: {
        group_id: 0,
        key: "",
        begin_at: "",
        end_at: "",
      },
      vipListData: [],
      vipPageConfig: { pageSize: 50, pageIndex: 1, counts: 0 },
    };
  },
  mounted() {
    onTableHeight(this, false);
    this.getList();
  },
  methods: {
    //查询
    handleSearch() {
      this.getList();
    },
    //重置
    resetSearchForm() {
      if (this.$refs.searchForm) this.$refs.searchForm.resetFields();
      this.getList();
    },
    //列表
    getList() {
      this.loading = true;
      vipApi
        .vipGroupList(this.searchForm)
        .then((res) => {
          this.listData = res.list;
        })
        .catch((res) => {})
        .finally(() => {
          this.loading = false;
        });
    },

    //删除
    deleteItem(row) {
      this.$confirm("确定是否删除该分组?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let message = this.$message({
            message: "请求中",
            iconClass: "el-icon-loading",
            customClass: "message-loading",
            duration: 0,
            offset: 60,
          });
          vipApi
            .vipGroupDelete({ id: row.id })
            .then((res) => {
              this.$message({
                message: "删除成功",
                type: "success",
                offset: 60,
              });
              this.getList();
            })
            .catch((res) => {})
            .finally(() => {
              message.close();
            });
        })
        .catch(() => {});
    },
    //表单
    doForm(row) {
      this.model = row;
      this.dialogTitle = this.model == null ? "新增分组" : "编辑分组";
      this.$refs.modelForm ? this.$refs.modelForm.resetFields() : null;
      this.$nextTick(() => {
        if (this.model != null) {
          this.modelForm.name = this.model.name;
        }
      });
      this.dialogVisible = true;
    },
    //提交
    handleSubmit() {
      this.$refs.modelForm.validate((valid) => {
        if (valid) {
          this.btnLogining = true;
          vipApi
            .vipGroupForm({
              id: this.model != null ? this.model.id : 0,
              ...this.modelForm,
            })
            .then((res) => {
              this.$message({
                message: "提交成功",
                type: "success",
                offset: 60,
              });
              this.dialogVisible = false;
              this.getList();
            })
            .catch((res) => {})
            .finally(() => {
              this.btnLogining = false;
            });
        }
      });
    },
    userList(row) {
      (this.vipSearchForm = {
        group_id: row.id,
        key: "",
        begin_at: "",
        end_at: "",
      }),
        (this.vipListData = []);
      this.vipPageConfig = { pageSize: 50, pageIndex: 1, counts: 0 };
      this.drawer = true;
      this.getVipList();
    },
    //查询
    handleVipSearch() {
      this.getVipList();
    },
    //重置
    resetVipSearchForm() {
      if (this.$refs.vipSearchForm) this.$refs.vipSearchForm.resetFields();
      this.getVipList();
    },
    //分页
    handleVipCurrentChange(val) {
      this.getVipList(val);
    },
    //列表
    getVipList(pageIndex = 1) {
      this.loading = true;
      vipApi
        .vipList({
          page_size: this.vipPageConfig.pageSize,
          page_index: pageIndex,
          ...this.vipSearchForm,
        })
        .then((res) => {
          this.vipListData = res.list;
          this.vipPageConfig.pageIndex = res.page.page_index;
          this.vipPageConfig.counts = res.page.counts;
        })
        .catch((res) => {})
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>